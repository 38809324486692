import React from 'react'
import { Link, graphql } from 'gatsby'
import Layout from '../../components/Layout'
import SEO from '../../components/seo'

class DallasTX extends React.Component {
	render() {
		const siteTitle =
			'Dallas Gate Design and Services - J&J Gates Service and Design'
		const siteDescription =
			'We offer professional gate design and installation in Dallas, TX. Call  to find out more about our gate services and free Estimates. See our location in Dallas.'

		return (
			<Layout location={this.props.location} title={siteTitle}>
				<SEO title={siteTitle} description={siteDescription} />

				<div className="container-fluid text-center headline gates-headline">
					<div className="container">
						<h1 className="text-white m-0">Dallas Gate Design and Services</h1>
						<hr />
					</div>
				</div>
				<div className="container gates-container">
					<div className="row">
						<div className="col-md-12">
							<h4>Our Dallas Location</h4>
							<p className="font-weight-bold">
								J & J Gates Service And Design 325 North ST. Paul Street #3100
								Dallas, TX 75201 <br />
								<a href="tel:8174662794">(817)-466-2794</a>
							</p>
							<hr />
							<p>
								Looking for a reliable source for custom gates and gate
								installation in Dallas, TX? As a smart consumer, you’ll likely
								do a lot of research before you make a big investment. So, once
								you have what you want in mind, ask the various companies you
								reach out to how they can make the gate design you’re thinking
								of happening. Many will tell you that’s not within their
								capabilities. Others might give you referrals (which might end
								up being us anyway because many local contractors do refer to
								us).
							</p>
							<p>
								Make sure you check out our rating at the BBB. While not
								accredited with the BBB, they still keep ratings on nearly every
								kind of local business. Just Google{' '}
								<em>“JJ Gates Fort Worth BBB”</em> to see our rating.
							</p>
							<p>
								Just call J&J today at{' '}
								<a href="tel:8174662794">(817)-466-2794</a> or{' '}
								<Link to="/contact-us">contact us online</Link>
								for your FREE quote – there’s absolutely no obligation to buy.
							</p>
						</div>
					</div>
					<div className="row">
						<div className="col-md-12">
							<h4>Answers to Common Questions</h4>
							<h5>1. What types of gates do you install?</h5>
							<p>
								Anything you want. Ornamental wrought iron is the most
								decorative. Think of the types of gates and fences you see
								protecting large estates and important buildings to envision
								that. You can also get wood, which is decorative. Chain link
								gates are mostly for security purposes and cost-conscious
								customers. We also do turnstiles for businesses. And we also
								take care of the gate openers.
							</p>
							<h5>2. Can I get a manual gate?</h5>
							<p>
								You sure can. While the disadvantage is you have to open it
								yourself, the advantage is that you don’t have to worry about
								losing power and the ability to enter or leave your property.
								Most customers in Dallas, TX go with automatic driveway gates
								though.
							</p>
							<h5>3. Do you offer any warranties?</h5>
							<p>Yes, for all gate operators, you get a 2-year warranty.</p>
							<h5>
								4. Should I go with sliding or swinging automatic driveway
								gates?
							</h5>
							<p>
								It depends on your preferences. With sliding gates, you get
								better access control, which leads to a lower likelihood of
								crime. With swinging gates, you have lesser maintenance and a
								gorgeous look. So, you just have to decide which makes the most
								sense for your needs.
							</p>
						</div>
					</div>
					<div className="row">
						<div className="col-md-12">
							<iframe
								style={{ border: 0 }}
								src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3354.3441402901713!2d-96.79959838483641!3d32.783133190980834!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x864e9921f42b8b91%3A0x3abc4b76b8bd5c6f!2sJ%26J+Gate+Service+and+Design!5e0!3m2!1sen!2sus!4v1450712356806"
								allowFullScreen="allowfullscreen"
								width="100%"
								height={450}
								frameBorder={0}
								title="Dallas TX"
							/>
						</div>
					</div>
				</div>
			</Layout>
		)
	}
}

export default DallasTX

export const DallasTXPageQuery = graphql`
	query DallasTXPageQuery {
		site {
			siteMetadata {
				title
				author
				description
			}
		}
	}
`
